import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Avatar, Card, CardContent } from '@material-ui/core';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(8, 0),
  },
  slider: {
    marginTop: theme.spacing(4),
  },
  card: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  name: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },
  review: {
    color: '#555555',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  response: {
    color: '#1b77bb',
    fontStyle: 'italic',
  },
}));

const testimonials = [
  {
    name: 'M Usman Mustafa',
    review: 'SF Ali Travel Services made my Dubai trip unforgettable with their seamless booking process, exceptional hotel recommendation, and outstanding customer service. The luxurious accommodation exceeded my expectations, and their attentive …More',
    photos: 2,
  },
  {
    name: 'Javeria Qasim',
    review: 'This was my first travel experience and I must say you are truly one of the best agency I have had the pleasure to interact with. I Thank-you very much for all the work and dedication to make this spiritual trip happen.',
    photos: 7,
  },
  {
    name: 'Farrukh Najam',
    review: 'Just came back from Singapore with best fare through Sf Ali travels. Wonderful ticketing service, responsive and always on time. Highly Recommend',
    photos: 1,
  },
  {
    name: 'Milhan Qadri',
    review: 'Amazing agency! Best ticketing service & good fares... highly recommended',
    photos: 1,
  },
  {
    name: 'Aafia Imran',
    review: 'My go to travel agency for getting the best fares & service thank you SF Ali Travels for always being there!',
    photos: 0,
  },
  {
    name: 'Mekail Alam',
    review: 'Looking for lowest fares? This is the place to go. Highly recommended',
    photos: 1,
  },
];

const Testimonials = () => {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Testimonials
        </Typography>
        <Slider {...settings} className={classes.slider}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <Card className={classes.card}>
                <CardContent>
                  {/* <Avatar className={classes.avatar} src={`https://via.placeholder.com/64?text=${testimonial.photos}+photos`} /> */}
                  <Typography variant="h6" className={classes.name}>
                    {testimonial.name}
                  </Typography>
                  <Typography variant="body1" className={classes.review}>
                    {testimonial.review}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default Testimonials;
