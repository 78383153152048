import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box, Paper } from '@mui/material';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.web3forms.com/submit', {
        access_key: 'a8425f3d-bc4d-4006-b63b-f8f57c9bbd20',
        ...formData
      });

      if (response.data.success) {
        setResponseMessage('Thank you for your message! We will get back to you soon.');
        setFormData({ name: '', email: '', number: '', message: '' });
      } else {
        setResponseMessage('Something went wrong. Please try again.');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again later.');
    }

    // Show the response message as a prompt
    alert(responseMessage);
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ backgroundColor: '#1b77bb', minHeight: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ width: '100%', maxWidth: '600px', padding: '2rem' }}>
        <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: 'white' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Contact Us
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Number"
              name="number"
              type="tel"
              value={formData.number}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              required
            />
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Submit
            </Button>
          </form>
          {responseMessage && (
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, fontWeight: 'bold' }}>
              {responseMessage}
            </Typography>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default ContactForm;
